export default {
  time1: {
    value: 1,
    text: "9:00 - 18:00",
    from: "09:00:00.000+07:00",
    to: "18:00:00.000+07:00",
  },
  time2: {
    value: 2,
    text: "12:00 - 18:00",
    from: "12:00:00.000+07:00",
    to: "18:00:00.000+07:00",
  },
};
